import React from 'react';
import "../App.css";
import Logo from "./Img/logo.png";
import moment from 'moment';

export default function PrintSalary({ companyData, salaries, month, year }) {
    console.log("companyData", companyData);
    const formatCurrency = (amount) => {
        if (amount === null || amount === undefined) return "0.00";
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    const totals = salaries.reduce(
        (acc, v) => {
            acc.basicSalary += v.base_salary || 0;
            acc.grossSalary += Math.round(v.grossSalary) || 0;
            acc.otRate += Math.round(v.OT_rate) || 0;
            acc.otHours += v.OT_hours || 0;
            acc.otAmount += Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)) || 0;
            acc.lateCount += v.lateCount || 0;
            acc.halfDayCount += v.halfDayCount || 0;
            acc.advanceDeduction += v.advance_deduction || 0;
            acc.loanDeduction += v.loan_deduction || 0;
            acc.subTotals += Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)) + Math.round(v.totalSalary);
            acc.allowance += v.conveyance + v.incentive + v.other_allowance || 0;
            acc.netPayable += Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)) + Math.round(v.totalSalary) + (v.conveyance + v.incentive + v.other_allowance) - (v.advance ? v.advance : 0 + v.loan ? v.loan : 0 + v.tax ? v.tax : 0);
            return acc;
        },
        {
            basicSalary: 0,
            grossSalary: 0,
            otRate: 0,
            otHours: 0,
            otAmount: 0,
            lateCount: 0,
            halfDayCount: 0,
            advanceDeduction: 0,
            loanDeduction: 0,
            subTotals: 0,
            allowance: 0,
            netPayable: 0,
        }
    );

    return (
        <div id="printable-salary-component" style={{ width: '100%', margin: '0 auto', fontFamily: 'Arial, sans-serif' }}>
            <div style={{ display:"flex",justifyContent:"space-between",textAlign: 'center', marginBottom: '20px' }}>
                <img src={Logo} alt="Company Logo" style={{ maxWidth: '100px',fontSize:"20px" }} />
                <div>
                <h2 style={{ fontSize: '1.5rem', color: '#333',textDecoration:"underline" }}>{companyData[0].companyName}</h2>
                <h2 style={{ fontSize: '1.2rem', color: '#333',textDecoration:"underline" }}>Salary Sheet for the month of {moment(month,"M").format("MMMM")} {year}</h2>
                </div>
                <div></div>
            </div>
            <div style={{display:"flex",justifyContent:"space-between",marginBottom: '20px' }}>
                <div style={{textDecoration:"underline"}}>Working Days: <span>{moment(month,"M").daysInMonth()}</span></div>
                <div style={{textDecoration:"underline"}}>Date: <span>{moment(`${month}-${year}`,"M-YYYY").format('DD-MMMM-YYYY')}</span></div>
            </div>
            <table style={{ width: '100%', borderCollapse: 'collapse', border: '2px solid #ccc' }} cellSpacing="0">
                <thead>
                    <tr>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>S. No.</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>ID | Emp. Name</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Designation</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Basic Salary</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Days Present</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Gross Salary</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Overtime Rate</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Overtime Hours</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>O.T Amount</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Late</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Half Day</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Advance</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Loan</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Sub Total</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Allowance</th>
                        <th style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc', backgroundColor: '#4CAF50', color: 'white', fontWeight: 'bold' }}>Net Payable</th>
                    </tr>
                </thead>
                <tbody>
                    {salaries.map((v, i) => (
                        <tr key={v.salary_id}>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{(i + 1).toString().length < 2 ? "0" + (i + 1) : i + 1}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{v.employee_id + " | " + v.employeename}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{v.designationName}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{formatCurrency(v.base_salary)}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{v.totalDaysWorked}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{formatCurrency(Math.round(v.grossSalary))}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{formatCurrency(Math.round(v.OT_rate))}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{v.OT_hours ? v.OT_hours.toFixed(2) : 0}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{formatCurrency(Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)))}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{v.lateCount ? v.lateCount : 0}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{v.halfDayCount ? v.halfDayCount : 0}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{formatCurrency(v.advance_deduction ? v.advance_deduction : 0)}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{formatCurrency(v.loan_deduction ? v.loan_deduction : 0)}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{formatCurrency(Math.round((v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)) + Math.round(v.totalSalary))}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>{formatCurrency(v.conveyance + v.incentive + v.other_allowance)}</td>
                            <td style={{ padding: '10px', textAlign: 'center', verticalAlign: 'middle', border: '1px solid #ccc' }}>
                                {formatCurrency(Math.round(
                                    (v.OT_hours ? v.OT_hours : 0) * Math.round(v.OT_rate)
                                ) +
                                    Math.round(v.totalSalary) +
                                    (v.conveyance + v.incentive + v.other_allowance) -
                                    (v.advance
                                        ? v.advance
                                        : 0 + v.loan
                                            ? v.loan
                                            : 0 + v.tax
                                                ? v.tax
                                                : 0))}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan="3" style={{ fontWeight: "bold", border: '1px solid #ccc' }}>Totals</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.basicSalary)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}></td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.grossSalary)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.otRate)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{totals.otHours.toFixed(2)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.otAmount)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{totals.lateCount}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{totals.halfDayCount}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.advanceDeduction)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.loanDeduction)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.subTotals)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.allowance)}</td>
                        <td style={{ border: '1px solid #ccc', textAlign: 'center' }}>{formatCurrency(totals.netPayable)}</td>
                    </tr>
                </tfoot>
            </table>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', textAlign: 'center' }}>
                <p style={{ margin: '10px 0' }}>Prepared By: <span style={{ textDecoration: "underline" }}>{companyData[0].preparedBy}</span></p>
                <p style={{ margin: '10px 0' }}>Authorized By: <span style={{ textDecoration: "underline" }}>{companyData[0].authorizedBy}</span></p>
            </div>
        </div>
    );
} 






